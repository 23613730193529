.segment {
    padding: 14px 0px;
    font-size: 14px;
    font-weight: 500;
}
.segmentActive {
    color: var(--primary);
    transition: 300ms;
}

.segmentNotActive {
    color: rgb(70, 70, 70);
    background: #CCCCCC;
    transition: 300ms;
}

.no-padding {
    padding: 0rem 1.0rem
}

.no-border-bottom {
    border-bottom: 0;
}

.visible {
    display: block;
    transition: 300ms;
}

.invisible {
    display: none;
    transition: 300ms;
}

.forgot-password {
    cursor: pointer;
    text-decoration: underline;
    color: var(--primary);
}

.btn-google {
    background: white;
    border: 1px solid #cccccc;
}

.btn-facebook {
    background: white;
    border: 1px solid #cccccc;
}

.img-logo {
    display: inline-block;
    margin-right: 10px;
    width: 20px;
}

.white {
    background: white;
}

.title-register {
    font-size: 20px;
    color: var(--primary);
}
.logo-option-select{
    max-height: 17px;
}
.select-country {
    display: inline-block;
    margin-right: 13px;
    max-width: 20px;
}
.select-country .MuiFormControl-root {
    display: inline-flex;
}

.prueba {
    display: inline-flex;
}
.element-custom{
    display: inline-block;
}
