.img-testimony {
    display: block;
    max-height: 100%;
    margin-right: auto;
    margin-left: auto;
}

.text-testimony-mobile {
    font-size: 13px;
}
