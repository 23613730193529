.icon-menu-mobile {
    display: none;
    position: fixed;
    top: 20px;
    left: 10px;
    z-index: 2;
}
.menu-mobile {
    top:0;
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 35;
}

.menu-mobile-invisible {
    display: none;
}
.menu-left {
    display: inline-block;
    height: 100vh;
    width: 80%;
    background: white;
    z-index: 999 !important;
}
.menu-rigth {
    display: inline-block;
    position:fixed;
    top:0;
    right: 0;
    height: 100vh;
    width: 20%;
    background: black;
    opacity: 0.5;
}
.close-button-menu {
    margin: 20px 10px !important;
}

@media (max-width: 768px) {
    .icon-menu-mobile {
        display: inline-block;
    }
}
