.footer-no-background {
    background-color: transparent;
}

.img-product {
    max-width: 100%;
    border-radius: 5px;
}

.title-product {
    font-weight: bold;
    font-size: 18px;
    color: var(--primary);
}

.check-product {
    position: absolute;
    top: 3px;
    left: -3px;
}