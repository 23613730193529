.item-menu-main-active {
    color: white !important;
    background: linear-gradient(to right, rgba(25, 163, 223,1) 0%, rgba(25, 163, 223,1) 0%, rgba(51, 193, 255,1) 100%) !important;
}

.item-menu-main {
    margin-left: 5px;
    border-radius: 50px 10px 10px 50px;
    cursor: pointer;
    margin-bottom: 10px;
    color: #1d2129;
    height: 48px;
    transition: 100ms;
}

.item-menu-main:hover {
    background: #cecece;
    transition: 100ms;
}
.text-menu {
    color: inherit;
    text-decoration: none !important;
}

.text-menu:hover {
    color: inherit;
}

.title-menu {
    margin-left: 20px;
    font-size: 22px;
}

.icon-menu {
    font-size: 18px;
    padding: 15px 1px 1px 20px;
    color: #757575
}
@media (max-width: 768px) {
    .menu-app {
        display: none;
    }
    .menu-app-visible-mobile {
        display: block !important;
    }
}
