.logo-home {
    max-height: 140px;
}

.carousel-control-prev-icon {
   background-image: url("../../assets/img/back.svg");
}
.carousel-control-next-icon {
    background-image: url("../../assets/img/next.svg");
}
.section-2-home {
    background-color: #F1F1F1;
}
.slide-how-it-works {
    width: 100%;
    background-color: transparent;
    border-radius: 5px;
    font-size: 30px;
    justify-content: center;
}
.content-slide-how-it-works {
    margin-top: 40%;
}
.phone-slide-how-it-works {
    width: 60%;
    margin-bottom: 28px;
}
.other-city {
    margin-left: 38%;
}
.img-city {
    margin-top: 10px;
    border-radius: 5px;
}
.text-city {
    margin-top: 6px;
    font-size: 15px;
}
@media (max-width: 768px) {
    .logo-home {
        max-height: 100px;
        margin-top: 35px;
    }
    .slide-how-it-works {
        font-size: 18px;
    }
    .phone-slide-how-it-works {
        width: 100%;
    }
}
