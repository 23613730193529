.link {
    margin-bottom: 15px;
    text-decoration: underline;
    font-size: 20px;
    color: var(--primary);
}

.buttonMercadoLibre { 
    font-weight: bold;
    color: black;
    font-size: 1.6vw;
    background-color: yellow;
    border: 1px solid yellow;
}

/* .buttonMercadoLibre:focus {
    outline: none;
} */

.buttonMercadoLibre:hover {
    font-weight: bold;
    color: black;
    font-size: 1.6vw;
    background-color: rgb(230, 230, 2);
    border: 1px solid yellow;
}
