div.overlay {
    display:        table;
    position:       fixed;
    top:            0;
    left:           0;
    width:          100%;
    height:         100%;
    z-index: 18;
}
div.overlay > div {
    display:        table-cell;
    width:          100%;
    height:         100%;
    background:     rgba(2,3,3,.3);
    text-align:     center;
    vertical-align: middle;
}

.div-content-loading {
    background-color: white;
    margin-right: auto;
    margin-left: auto;
    display: inline-block;
    width: 200px;
    height: 200px;
    border-radius: 38px;
}
.div-content-loading img {
    max-width: 100%;
    border-radius: 38px;
}
