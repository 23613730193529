.custom-header {
    display: flex;
    position: relative;
    height: 86px;
    width: 100%;
    background-color: white;
    justify-content: flex-end;
    align-items: center;
    padding: 4% 8%;
    box-shadow: 0 6px 10px -7px gray;
    z-index: 17;
}
.custom-header-fixed {
    position: fixed !important;
    box-shadow: none;
}
.space-header {
    height: 86px;
}
.custom-header li,.custom-header a,.custom-header button {
    text-decoration: none;
}
.custom-header .logo {
    cursor: pointer;
    margin-right: auto;
}

.custom-header .nav_links {
    display: inline-block;
    padding: 0px 20px;
}

.custom-header .nav_links li {
    display: inline-block;
    padding: 0px 20px;
}
.custom-header-content .nav_links {
    display: inline-block;
    margin-top:50%;
    padding: 0px 20px;
}

.custom-header-content .nav_links li {
    display: block;
    padding: 4px 20px;
}
.custom-header-nav-link {
    color: #636363;
    font-size: 20px;
    transition: all 0.3s ease 0s;
}
.custom-header-nav-link:hover {
    color: var(--primary);
}
.custom-header-active-nav-item {
    color: var(--primary);
}

.custom-header-button {
    padding: 9px 25px;
    background-color: var(--primary);
    border:none;
    color: white;
    border-radius: 50px;
    cursor: pointer;
    transition: all 0.3s ease 0s;
}

.custom-header-button:hover {
    background-color: #006ed6;
}
#trigger-toggle {
    cursor: pointer;
}
#custom-header-toggle-menu {
    display:none;
}
.custom-header-menu-mobile {
    z-index: 16;
    position:fixed;
    background-color: white;
    height: 100%;
    width:100%;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    -mos-transition: all 0.4s;
    -o-transition: all 0.4s;
    transition: all 0.4s;
}
.custom-header-menu-mobile-visible {
    top: 86px;
}
.custom-header-menu-mobile-invisible {
    top: -2000px;
}
.content-custom-menu-mobile {
    height: 100vh;
    background-color: white;
    position: absolute;
    z-index: 4;
}
.img-trigger-button {
    padding: 0px 10px;
}
.img-trigger-button img {
    margin-top: 6px;
}
.custom-header-content {
    width: auto;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

@media (max-width: 768px) {
    .custom-header {
        padding: 5px 5px;
    }
    .custom-header .nav_links {
        display: none;
    }
    .custom-header-content .nav_links {

    }
    .custom-header .logo {
        max-width: 120px;
    }
}

.float{
	position:fixed;
	width:60px;
	height:60px;
	bottom:40px;
	right:40px;
	background-color:rgb(10, 151, 116);
	color:#FFF;
	border-radius:50px;
	text-align:center;
	box-shadow: 2px 2px 3px #2F2F37;
}
.float:active {
    width:50PX;
    height:50px;
}
.my-float{
	margin-top:17%;
    margin-left:5%;
    width:40px;
    height:40px;
}
.my-float:active{
    width:30px;
    height:30px;
}