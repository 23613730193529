.container-date {
  width: 100%;
  background-color: rgb(215, 246, 255);
  border-radius: 10px;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  z-index: 5;
}
.card-date {
  display: inline-block;
}

.date-selected {
  cursor: pointer;
  margin: 10px 10px 10px 10px;
  width: 57px;
  border-radius: 24px;
  background: var(--primary);
  color: white;
  border: 1px solid var(--primary);
  padding: 8px 0px 8px 0px;
  display: inline-block;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.date-unselected {
  cursor: pointer;
  margin: 10px 10px 10px 10px;
  width: 57px;
  border-radius: 24px;
  background: white;
  color: #19a3df;
  border: 1px solid var(--primary);
  padding: 8px 0px 8px 0px;
  display: inline-block;
  justify-content: center;
  align-items: center;
  text-align: center;
}

@media (min-width: 600px) {
  #scroll-style::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
  }

  #scroll-style::-webkit-scrollbar {
    width: 6px;
    background-color: #f5f5f5;
  }

  #scroll-style::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: var(--primary);
  }
}

.hours {
  border: 3px solid var(--primary);
  border-radius: 10px;
  padding: 10px;
  width: 100%;
  overflow-y: auto;
  max-height: 350px;
}

.hours-options {
  z-index: 10;
}

.div-loading {
  width: 100%;
  height: 250px;
  z-index: 9999;
  text-align: center;
  background: #adadad;
}

.div-loading img {
  margin: auto;
  width: 100px;
  border-radius: 15px;
  margin-top: 65px;
}

.hour-div {
  font-size: 17px;
  border-bottom: 1px solid #c4c4c4;
  border-bottom-left-radius: 12px;
  padding-bottom: 5px;
  margin-bottom: 2px;
  cursor: pointer;
}
