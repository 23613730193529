.custom-map-button
{
    position: relative;
    width: 270px;
    height: 60px;
    margin-bottom:20px;
    padding: 0 10px;
    text-align: left;
    font-size: 16px;
    background: white;
    border: 1px solid var(--primary);
    border-radius: 30px;
}

.custom-map-button:focus {
    outline: none;
}

.custom-map-button:hover {
    padding: 0 9px;
    border: 2px solid var(--primary);
}
.custom-map-button:hover img {
    padding: 1px;
    max-width: 46px;
    transition: 100ms;
}
.custom-map-button:hover span {
    margin-left: 4px;
    transition: 100ms;
}
.custom-map-button img
{
    display: inline-block;
    vertical-align: middle;
    padding: 5px;
    max-width: 45px;
    transition: 100ms;
}


.custom-map-button span
{
    display:inline-block;
    margin-left: 5px;
    vertical-align:middle;
    color: #757575;
    transition: 100ms;
}
.selected-img {
    transition: 600ms;
    color: var(--primary);
    border: 2px solid var(--primary);
}

.icon-img {
    width: 80%;
    padding-left: 10%;
}

.div-country {
    margin-left: 10%;
    width: 80%;
    padding: 15% 15% 0% 15%;
    border-radius: 10px;
    cursor: pointer;
}

.check-city {
    position: absolute;
    right: 20%;
    top: 15%;
}
.check-country{
    position: absolute;
    top: -16px;
    left: -32px;
}
.padding-city-modal-city {
    padding: 20px 20px;
}
