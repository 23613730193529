.paymentType {
    border: 1px solid #c3c3c3;
    padding: 10px 15px 10px 15px;
    border-radius: 10px;
    text-align: center;
    cursor: pointer;
    transition: 150ms;
}
.paymentTypeActive {
    font-weight: bold;
    border: 2px solid var(--primary);
    padding: 10px 15px 10px 15px;
    border-radius: 10px;
    text-align: center;
    color: var(--primary);
    cursor: pointer;
    transition: 150ms;
}

.border-gray {
    border: 1px solid #c3c3c3;
    border-radius: 5px;
}
.padding-20 {
    padding: 20px
}
.hr {
    border-bottom: 1px solid #ccc;
    margin: 10px 0;
    width: 100%;
}

.text-20 {
    font-size: 20px;
    color: var(--primary);
}