.item-home .item-header {
    background: #19a3df;
    color: white;
    height: 32px;
    cursor: pointer;
    font-size: 18px;
    padding-left: 15px;
    padding-top: 2px;
}

.icon-item-header {
    position: absolute;
    right: 10px;
    top: 5px;
}

.padding-card {
    padding: 5px 5px 5px 5px;
}

.item-home {
    cursor: pointer;
}